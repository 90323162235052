<template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Name</label>
                            <v-text-field
                            v-model="name"
                            :rules="nameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Job Title</label>
                            <v-text-field
                            v-model="position"
                            :rules="positionRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" md="12 " sm="12" xs="12" >
                            <label for="">E-mail</label>
                            <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            required
                            outlined
                            dense
                            readonly
                            ></v-text-field>
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn  color="blue darken-1" @click="cancel" dark>
                                Cancel
                            </v-btn>

                            <v-btn  :disabled="!valid || loading" :color="`${zamuYellow3}`"  class="mr-4 zamu-sub-heading "  @click="save" dark >
                            Submit
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'EditTeam',
    mixins: [coreMixin, colorMixin],
     data () {
       return {
            member: null,
            name: '',
            position: '',
            email: '',
            notification: '',
            actionClass: '',
            formTitle: 'Edit Team Member',
             valid: false,
             loading: false,
             memberID: null,
       };
     },


    async mounted() {
       const id = this.$route.params.id;
       this.memberID = id;
        this.member = await this.retrieveMember(id);
            this.name = this.member.name;
            this.position = this.member.position;
            this.email = this.member.email;

    },
    methods: {
        async retrieveMember(id) {
              const endpoint = `team/member/${id}`;

              const fullPayload = {
                  app: this.apiUrl,
                  endpoint,
              };

              try {
                  const response = await this.axiosGetRequest(fullPayload);
                  const member = response.status === 200 ? response.data : null;
                  return member;
              } catch (error) {
                return null;
              }
          },
          async save(){
              const formvalid = this.$refs.form.validate();
              if(!formvalid) {
                  return;
              }

                this.loading = true;

                    const payload = {
                          name: this.name,
                          email: this.email,
                          position: this.position,
                      };
                      const fullPayload = {
                          app: this.apiUrl,
                          params: payload,
                          endpoint: `team/update/${this.memberID}`,
                      };
                        try {
                          const res = await this.axiosPutRequest(fullPayload);
                          this.loading = false;
                          this.notification = res.data ? 'Team member updated successful' : 'Team member failed to update';
                          this.actionClass = res.data  ? 'success' : 'error';
                       

                      } catch (error) {
                          this.notification = 'Team member failed to update. Contact the admin';
                          this.actionClass = 'error';
                          this.loading = false;
                          return error.response;
                      }

          },
          cancel () {
           this.$router.push('/admin/team');

          },
        }

}
</script>

<style>

</style>